import { useRef } from "react";

export const useSectionRefs = () => {
  const aboutRef = useRef(null);
  const ticketsRef = useRef(null);
  const speakersRef = useRef(null);
  const workshopsRef = useRef(null);
  const agendaRef = useRef(null);
  const sponsorsRef = useRef(null);
  const partnersRef = useRef(null);
  const exhibitorsRef = useRef(null);
  const venueRef = useRef(null);

  const sections = {
    about: aboutRef,
    tickets: ticketsRef,
    speakers: speakersRef,
    workshops: workshopsRef,
    agenda: agendaRef,
    sponsors: sponsorsRef,
    partners: partnersRef,
    exhibitors: exhibitorsRef,
    venue: venueRef,
  };

  return { sections };
};
